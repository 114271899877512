body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*
	minHeight: '100vh',
	lineHeight: '120px',
	color: '#fff',
	marginTop: 10,
	marginBottom: 10

*/
.contentStyle {
  min-height: '100vh';
  line-height: 120px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btnHeaderStyle {
  float: right;
  font-size: 16px;
  width: 64px !important;
  height: 64px !important;
}